import LuBillableCategory from './LuBillableCategory'
import ActivityCodes from './ActivityCodes'
import LeaveSlip from './LeaveSlip'
import Project from './Project'
import TimeSheet from './TimeSheet'
export default class TimeSheetEntry {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //TimeSheetID is required
      TimeSheetID: 0,
      //ProjectID is required
      ProjectID: 0,
      CodeID: 0,
      LeaveSlipID: null,
      //Hours is required
      Hours: 0,
      //Date is required
      Date: new Date().toISOString().split('T')[0],
      Note: '',
      IsBillable: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.TimeSheetID = obj.TimeSheetID
    this.ProjectID = obj.ProjectID
    this.CodeID = obj.CodeID
    this.LeaveSlipID = obj.LeaveSlipID
    this.Hours = obj.Hours
    this.Date = obj.Date
    this.Note = obj.Note
    this.BillableCategoryID = obj.BillableCategoryID

    this.BillableCategory = new LuBillableCategory(obj.BillableCategory || {})
    this.Code = new ActivityCodes(obj.Code || {})
    this.LeaveSlip = new LeaveSlip(obj.LeaveSlip || {})
    this.Project = new Project(obj.Project || {})
    this.TimeSheet = new TimeSheet(obj.TimeSheet || {})
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.BillableCategory = null
    obj.Code = null
    if (obj.LeaveSlipID != null && obj.LeaveSlipID >= 0 && this.LeaveSlip) {
      obj.LeaveSlip = this.LeaveSlip.root()
    } else {
      obj.LeaveSlip = null
    }
    obj.TimeSheet = null
    obj.Project = null
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.BillableCategory = null
    obj.Code = null
    obj.LeaveSlip = null
    obj.Project = null
    obj.TimeSheet = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.BillableCategory = null
    obj.Code = null
    obj.LeaveSlip = null
    obj.Project = null
    obj.TimeSheet = null
    return obj
  }
}
