import Contact from './Contact'
import AuditEntity from './AuditEntity'
export default class EntityContact {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      EntityId: 0,
      ContactId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.EntityId = obj.EntityId
    this.ContactId = obj.ContactId

    this.Contact = new Contact(obj.Contact || {})
    this.Entity = new AuditEntity(obj.Entity || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Contact = null
    obj.Entity = null
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Entity = null
    obj.Contact.getSaveData()
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Contact = null
    obj.Entity = null
    return obj
  }
}
