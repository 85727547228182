import Project from './Project'
import LuExciseProjectionType from './LuExciseProjectionType'
import ExciseExceptions from './ExciseExceptions'
export default class ExciseStratum {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      StratumNumber: null,
      //ProjectID is required
      ProjectID: 0,
      //Description is required
      Description: '',
      //ProjectionTypeID is required
      ProjectionTypeID: 0,
      //SampleSize is required
      SampleSize: 0,
      //StratumTotal is required
      StratumTotal: 0,
      //NumberMonths is required
      NumberMonths: 0,
      //IsActive is required
      IsActive: true,
      //IsAppended is required
      IsAppended: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.StratumNumber = obj.StratumNumber
    this.ProjectID = obj.ProjectID
    this.Description = obj.Description
    this.ProjectionTypeID = obj.ProjectionTypeID
    this.SampleSize = obj.SampleSize
    this.StratumTotal = obj.StratumTotal
    this.NumberMonths = obj.NumberMonths
    this.IsActive = obj.IsActive
    this.IsAppended = obj.IsAppended
    this.Project = new Project(obj.Project || {})
    this.ProjectionType = new LuExciseProjectionType(obj.ProjectionType || {})
    this.ExciseExceptions = []
    if (obj.ExciseExceptions) {
      obj.ExciseExceptions.forEach((item) => {
        this.ExciseExceptions.push(new ExciseExceptions(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Project = null
    obj.ProjectionType = null
    obj.ExciseExceptions = []
    if (this.ExciseExceptions) {
      this.ExciseExceptions.forEach((item) => {
        obj.ExciseExceptions.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Project = null
    obj.ProjectionType = null
    obj.ExciseExceptions = null
    return obj
  }
}
