import LuCensusReport from './LuCensusReport'
import LuCounty from './LuCounty'
// import AuditEntity from './AuditEntity'
import LuPFEntityType from './LuPFEntityType'
export default class AuditEntityPublicFunds {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //EntityID is required
      EntityID: 0,
      //DateEntered is required
      DateEntered: new Date().toISOString().split('T')[0],
      //EntityTypeID is required
      EntityTypeID: 0,
      FiscalMonthYearEnd: 6,
      OriginationDate: null,
      CensusReportID: null,
      //CountyID is required
      CountyID: null,
      CensusNumber: null,
      DissolvedDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.EntityID = obj.EntityID
    this.DateEntered = obj.DateEntered
    this.EntityTypeID = obj.EntityTypeID
    this.FiscalMonthYearEnd = obj.FiscalMonthYearEnd
    this.OriginationDate = obj.OriginationDate
    this.CensusReportID = obj.CensusReportID
    this.CountyID = obj.CountyID
    this.CensusNumber = obj.CensusNumber
    this.DissolvedDate = obj.DissolvedDate
    this.CensusReport = new LuCensusReport(obj.CensusReport || {})
    this.County = new LuCounty(obj.County || {})
    // this.Entity = new AuditEntity(obj.Entity || {})
    this.EntityType = new LuPFEntityType(obj.EntityType || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CensusReport = null
    obj.County = null
    obj.Entity = null
    obj.EntityType = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CensusReport = null
    obj.County = null
    obj.Entity = null
    obj.EntityType = null
    return obj
  }
}
