import Division from './Division'
import ProjectCollections from './ProjectCollections'
import ProjectFindings from './ProjectFindings'
export default class LuFindingType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      FindingType: null,
      //DivisionID is required
      DivisionID: 0,
      //Active is required
      Active: false,
      RequiresCounty: null,
      SortOrder: null,
      RequiresNOVCNumber: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.FindingType = obj.FindingType
    this.DivisionID = obj.DivisionID
    this.Active = obj.Active
    this.RequiresCounty = obj.RequiresCounty
    this.SortOrder = obj.SortOrder
    this.RequiresNOVCNumber = obj.RequiresNOVCNumber

    this.Division = new Division(obj.Division || {})
    this.ProjectCollections = []
    if (obj.ProjectCollections) {
      obj.ProjectCollections.forEach((item) => {
        this.ProjectCollections.push(new ProjectCollections(item))
      })
    }
    this.ProjectFindings = []
    if (obj.ProjectFindings) {
      obj.ProjectFindings.forEach((item) => {
        this.ProjectFindings.push(new ProjectFindings(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.ProjectCollections = []
    if (this.ProjectCollections) {
      this.ProjectCollections.forEach((item) => {
        obj.ProjectCollections.push(item.removeRelated())
      })
    }
    obj.ProjectFindings = []
    if (this.ProjectFindings) {
      this.ProjectFindings.forEach((item) => {
        obj.ProjectFindings.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.ProjectCollections = null
    obj.ProjectFindings = null
    return obj
  }
}
