import ExciseExceptions from './ExciseExceptions'
export default class LuExciseTransactionType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      //IsActive is required
      IsActive: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.IsActive = obj.IsActive
    this.ExciseExceptions = []
    if (obj.ExciseExceptions) {
      obj.ExciseExceptions.forEach((item) => {
        this.ExciseExceptions.push(new ExciseExceptions(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ExciseExceptions = []
    if (this.ExciseExceptions) {
      this.ExciseExceptions.forEach((item) => {
        obj.ExciseExceptions.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ExciseExceptions = null
    return obj
  }
}
