import User from './User'
export default class EmployeePayType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description =  obj.Description

    this.User = []
    if (obj.User) {
      obj.User.forEach((item) => {
        this.User.push(new User(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.User = []
    if (this.User) {
      this.User.forEach((item) => {
        obj.User.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.User = null
    return obj
  }
}
