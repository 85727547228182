import User from './User'
import LuCity from './LuCity'
import LuCounty from './LuCounty'
import LuExciseIssues from './LuExciseIssues'
import LuSpecialDistrict from './LuSpecialDistrict'
import ExciseStratum from './ExciseStratum'
import LuExciseTaxTypes from './LuExciseTaxTypes'
import LuExciseTransactionType from './LuExciseTransactionType'
import dayjs from 'dayjs'
export default class ExciseExceptions {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //StratumID is required
      StratumID: 0,
      //stratum number is only used to process imports from excel files to map to the stratumid
      StratumNumber: null,
      //added for importing reconciliation issues
      ReportedTax: 0,
      AuditedTax: 0,
      //InvoiceDate is required
      InvoiceDate: dayjs().toDate(),
      //DocumentNumber is required
      DocumentNumber: '',
      //IssueID is required
      IssueID: 0,
      //TransactionTypeID is required
      TransactionTypeID: 0,
      //TaxTypeID is required
      TaxTypeID: 0,
      //SoldFromCountyID is required
      // SoldFromCountyID: 0,
      DeliveredToCityID: 0,
      //DeliveredToCountyID is required
      DeliveredToCountyID: 0,
      SpecialDistrictID: 0,
      //VendorCustomer is required
      VendorCustomer: '',
      //Description is required
      Description: '',
      //TaxableAmount is required
      TaxableAmount: 0,
      //TaxPaid is required
      TaxPaid: 0,
      //Reference is required
      Reference: '',
      //IsActive is required
      IsActive: true,
      //IsAppended is required
      IsAppended: false,
      //CreatedByID is required
      CreatedByID: 0,
      // SubDescription: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)

    if (
      (typeof obj.IsActive == 'string' &&
        (obj.IsActive.toLowerCase() == 'true' ||
          obj.IsActive.toLowerCase() == 'active')) ||
      obj.IsActive == 1 ||
      obj.IsActive == true
    ) {
      obj.IsActive = true
    } else {
      obj.IsActive = false
    }
    if (
      (typeof obj.IsAppended == 'string' &&
        (obj.IsAppended.toLowerCase() == 'true' ||
          obj.IsAppended.toLowerCase() == 'active')) ||
      obj.IsAppended == 1 ||
      obj.IsAppended == true
    ) {
      obj.IsAppended = true
    } else {
      obj.IsAppended = false
    }
    if (typeof obj.TaxableAmount == 'string') {
      obj.TaxableAmount = parseFloat(obj.TaxableAmount.replace('$', ''))
    }
    if (typeof obj.TaxPaid == 'string') {
      obj.TaxPaid = parseFloat(obj.TaxPaid.replace('$', ''))
    }
    obj.InvoiceDate = dayjs(obj.InvoiceDate).toDate()
    // .format('MM/DD/YYYY')
    // if (typeof obj.InvoiceDate == 'object') {
    // } else if (typeof obj.InvoiceDate == 'string') {
    //   obj.InvoiceDate = dayjs(obj.InvoiceDate).toDate()// .format('MM/DD/YYYY')
    // }

    this.ID = obj.ID
    this.StratumID = obj.StratumID
    this.StratumNumber = obj.StratumNumber
    this.ReportedTax = obj.ReportedTax
    this.AuditedTax = obj.AuditedTax
    this.InvoiceDate = obj.InvoiceDate
    this.DocumentNumber = obj.DocumentNumber
    this.IssueID = obj.IssueID
    this.TransactionTypeID = obj.TransactionTypeID
    this.TaxTypeID = obj.TaxTypeID
    // this.SoldFromCountyID = obj.SoldFromCountyID
    this.DeliveredToCityID = obj.DeliveredToCityID
    this.DeliveredToCountyID = obj.DeliveredToCountyID
    this.SpecialDistrictID = obj.SpecialDistrictID
    this.VendorCustomer = obj.VendorCustomer
    this.Description = obj.Description
    this.TaxableAmount = obj.TaxableAmount
    this.TaxPaid = obj.TaxPaid
    this.Reference = obj.Reference
    this.IsActive = obj.IsActive
    this.IsAppended = obj.IsAppended
    this.CreatedByID = obj.CreatedByID
    // this.SubDescription = obj.SubDescription
    this.CreatedBy = new User(obj.CreatedBy || {})
    this.DeliveredToCity = new LuCity(obj.DeliveredToCity || {})
    this.DeliveredToCounty = new LuCounty(obj.DeliveredToCounty || {})
    this.Issue = new LuExciseIssues(obj.Issue || {})
    this.SoldFromCounty = new LuCounty(obj.SoldFromCounty || {})
    this.SpecialDistrict = new LuSpecialDistrict(obj.SpecialDistrict || {})
    this.Stratum = new ExciseStratum(obj.Stratum || {})
    this.TaxType = new LuExciseTaxTypes(obj.TaxType || {})
    this.TransactionType = new LuExciseTransactionType(
      obj.TransactionType || {}
    )
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.InvoiceDate = dayjs(obj.InvoiceDate)
      .toDate()
      .toISOString()
      .split('T')[0]
    obj.CreatedBy = null
    obj.DeliveredToCity = null
    obj.DeliveredToCounty = null
    obj.Issue = null
    obj.SoldFromCounty = null
    obj.SpecialDistrict = null
    obj.Stratum = null
    obj.TaxType = null
    obj.TransactionType = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.InvoiceDate = dayjs(obj.InvoiceDate)
      .toDate()
      .toISOString()
      .split('T')[0]
    obj.CreatedBy = null
    obj.DeliveredToCity = null
    obj.DeliveredToCounty = null
    obj.Issue = null
    obj.SoldFromCounty = null
    obj.SpecialDistrict = null
    obj.Stratum = null
    obj.TaxType = null
    obj.TransactionType = null
    return obj
  }
}
