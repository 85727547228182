import EntityAddress from './EntityAddress'
import Division from './Division'
import AuditEntityPublicFunds from './AuditEntityPublicFunds'
import AuditEntityDivision from './AuditEntityDivision'
import AuditSubEntity from './AuditSubEntity'
import EntityContact from './EntityContact'
export default class AuditEntity {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      FEIN: '',
      FullName: '',
      ShortName: '',
      Phone: '',
      Extension: '',
      Fax: '',
      Website: null,
      AddressId: 0,
      IsActive: true,
      TaxNum: '',
      TaxNumDesc: '',
      // DivisionID: null,
      ParentEntity: null,
      TempField: null,
      TempParent: null,
      PAddressID: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.FEIN = obj.FEIN
    this.FullName = obj.FullName
    this.ShortName = obj.ShortName
    this.Phone = obj.Phone
    this.Extension = obj.Extension
    this.Fax = obj.Fax
    this.Website = obj.Website
    this.AddressId = obj.AddressId
    this.IsActive = obj.IsActive
    this.TaxNum = obj.TaxNum
    this.TaxNumDesc = obj.TaxNumDesc
    // this.DivisionID = obj.DivisionID
    this.ParentEntity = obj.ParentEntity
    this.TempField = obj.TempField
    this.TempParent = obj.TempParent
    this.PAddressID = obj.PAddressID

    this.Address = new EntityAddress(obj.Address || {})
    this.PAddress = new EntityAddress(obj.PAddress || {})
    this.AuditEntityPublicFunds = new AuditEntityPublicFunds(
      obj.AuditEntityPublicFunds || {}
    )
    // this.Division = new Division(obj.Division || {})
    this.AuditEntityDivision = []
    if (obj.AuditEntityDivision) {
      obj.AuditEntityDivision.forEach((item) => {
        this.AuditEntityDivision.push(new AuditEntityDivision(item))
      })
    }
    this.AuditSubEntity = []
    if (obj.AuditSubEntity) {
      obj.AuditSubEntity.forEach((item) => {
        this.AuditSubEntity.push(new AuditSubEntity(item))
      })
    }
    this.EntityContact = []
    if (obj.EntityContact) {
      obj.EntityContact.forEach((item) => {
        this.EntityContact.push(new EntityContact(item))
      })
    }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Address = this.Address.root()
    obj.PAddress = this.PAddress.root()
    if (
      JSON.stringify(this.AuditEntityPublicFunds.root()) ==
      JSON.stringify(new AuditEntityPublicFunds().root())
    ) {
      obj.AuditEntityPublicFunds = null
    } else {
      obj.AuditEntityPublicFunds = this.AuditEntityPublicFunds.root()
    }
    obj.AuditEntityPublicFunds = this.AuditEntityPublicFunds.root()
    // obj.Division = null
    // when we are creating an entity we need to add the division relationship also
    obj.AuditEntityDivision = []
    if (this.AuditEntityDivision) {
      this.AuditEntityDivision.forEach((item) => {
        obj.AuditEntityDivision.push(item.removeRelated())
      })
    }
    obj.AuditSubEntity = null
    obj.EntityContact = null
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Address = null
    obj.PAddress = null
    obj.AuditEntityPublicFunds = null
    // obj.Division = null
    obj.AuditEntityDivision = []
    if (this.AuditEntityDivision) {
      this.AuditEntityDivision.forEach((item) => {
        obj.AuditEntityDivision.push(item.removeRelated())
      })
    }
    obj.AuditSubEntity = []
    if (this.AuditSubEntity) {
      this.AuditSubEntity.forEach((item) => {
        obj.AuditSubEntity.push(item.removeRelated())
      })
    }
    obj.EntityContact = []
    if (this.EntityContact) {
      this.EntityContact.forEach((item) => {
        obj.EntityContact.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Address = null
    obj.PAddress = null
    obj.AuditEntityPublicFunds = null
    // obj.Division = null
    obj.AuditEntityDivision = null
    obj.AuditSubEntity = null
    obj.EntityContact = null
    return obj
  }
}
