import Contact from './Contact'
import AuditSubEntity from './AuditSubEntity'
export default class SubEntityContact {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      SubEntityId: null,
      ContactId: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.SubEntityId = obj.SubEntityId
    this.ContactId = obj.ContactId

    this.Contact = new Contact(obj.Contact || {})
    this.SubEntity = new AuditSubEntity(obj.SubEntity || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Contact = null
    obj.SubEntity = null
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Entity = null
    obj.SubEntity = null
    obj.Contact.getSaveData()
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Contact = null
    obj.SubEntity = null
    return obj
  }
}
