import LuState from './LuState'
import AuditEntity from './AuditEntity'
import AuditSubEntity from './AuditSubEntity'
import Contact from './Contact'
export default class EntityAddress {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Address1: '',
      City: '',
      StateId: 50,
      Zip: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Address1 = obj.Address1
    this.City = obj.City
    this.StateId = obj.StateId
    this.Zip = obj.Zip

    this.State = new LuState(obj.State || {})
    this.AuditEntityAddress = []
    if (obj.AuditEntityAddress) {
      obj.AuditEntityAddress.forEach((item) => {
        this.AuditEntityAddress.push(new AuditEntity(item))
      })
    }
    this.AuditEntityPAddress = []
    if (obj.AuditEntityPAddress) {
      obj.AuditEntityPAddress.forEach((item) => {
        this.AuditEntityPAddress.push(new AuditEntity(item))
      })
    }
    this.AuditSubEntityAddress = []
    if (obj.AuditSubEntityAddress) {
      obj.AuditSubEntityAddress.forEach((item) => {
        this.AuditSubEntityAddress.push(new AuditSubEntity(item))
      })
    }
    this.AuditSubEntityPAddress = []
    if (obj.AuditSubEntityPAddress) {
      obj.AuditSubEntityPAddress.forEach((item) => {
        this.AuditSubEntityPAddress.push(new AuditSubEntity(item))
      })
    }
    this.Contact = []
    if (obj.Contact) {
      obj.Contact.forEach((item) => {
        this.Contact.push(new Contact(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.State = null
    obj.AuditEntityAddress = []
    if (this.AuditEntityAddress) {
      this.AuditEntityAddress.forEach((item) => {
        obj.AuditEntityAddress.push(item.removeRelated())
      })
    }
    obj.AuditEntityPAddress = []
    if (this.AuditEntityPAddress) {
      this.AuditEntityPAddress.forEach((item) => {
        obj.AuditEntityPAddress.push(item.removeRelated())
      })
    }
    obj.AuditSubEntityAddress = []
    if (this.AuditSubEntityAddress) {
      this.AuditSubEntityAddress.forEach((item) => {
        obj.AuditSubEntityAddress.push(item.removeRelated())
      })
    }
    obj.AuditSubEntityPAddress = []
    if (this.AuditSubEntityPAddress) {
      this.AuditSubEntityPAddress.forEach((item) => {
        obj.AuditSubEntityPAddress.push(item.removeRelated())
      })
    }
    obj.Contact = []
    if (this.Contact) {
      this.Contact.forEach((item) => {
        obj.Contact.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.State = null
    obj.AuditEntityAddress = null
    obj.AuditEntityPAddress = null
    obj.AuditSubEntityAddress = null
    obj.AuditSubEntityPAddress = null
    obj.Contact = null
    return obj
  }
}
