import User from './User'
import Project from './Project'
export default class ProjectExpenses {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      POV: null,
      StateCar: null,
      AirFare: null,
      Hotel: null,
      MIE: null,
      RentalCar: null,
      Other: null,
      Comments: null,
      AuditorID: null,
      EnteredBy: null,
      EnteredDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.POV = obj.POV
    this.StateCar = obj.StateCar
    this.AirFare = obj.AirFare
    this.Hotel = obj.Hotel
    this.MIE = obj.MIE
    this.RentalCar = obj.RentalCar
    this.Other = obj.Other
    this.Comments = obj.Comments
    this.AuditorID = obj.AuditorID
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate

    this.Auditor = new User(obj.Auditor || {})
    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.Project = new Project(obj.Project || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Auditor = null
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Auditor = null
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
}
