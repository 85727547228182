import LuExamType from './LuExamType'
// import Project from './Project'
export default class ProjectBanking {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      LocationNumber: null,
      Comments: null,
      ExamTypeID: null,
      CARRequired: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.LocationNumber = obj.LocationNumber
    this.Comments = obj.Comments
    this.ExamTypeID = obj.ExamTypeID
    this.CARRequired = obj.CARRequired

    this.ExamType = new LuExamType(obj.ExamType || {})
    //cicular reference.. do not load
    // this.Project = new Project(obj.Project || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ExamType = null
    obj.Project = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ExamType = null
    obj.Project = null
    return obj
  }
}
