import Division from './Division'
import ProjectDate from './ProjectDate'
export default class LuDateType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      DateType: null,
      DivisionID: null,
      FindingsRequired: null,
      IsActive: null,
      SortOrder: null,
      ShowEndDate: null,
      SentToAvailable: null,
      Prefix: null,
      StartDateDisplay: null,
      EndDateDisplay: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.DateType = obj.DateType
    this.DivisionID = obj.DivisionID
    this.FindingsRequired = obj.FindingsRequired
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder
    this.ShowEndDate = obj.ShowEndDate
    this.SentToAvailable = obj.SentToAvailable
    this.Prefix = obj.Prefix
    this.StartDateDisplay = obj.StartDateDisplay
    this.EndDateDisplay = obj.EndDateDisplay

    this.Division = new Division(obj.Division || {})
    this.ProjectDate = []
    if (obj.ProjectDate) {
      obj.ProjectDate.forEach((item) => {
        this.ProjectDate.push(new ProjectDate(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.ProjectDate = []
    if (this.ProjectDate) {
      this.ProjectDate.forEach((item) => {
        obj.ProjectDate.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.ProjectDate = null
    return obj
  }
}
