import LuDateType from './LuDateType'
import User from './User'
import Project from './Project'
import LuSentTo from './LuSentTo'
import ProjectFindings from './ProjectFindings'
export default class ProjectDate {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      //DateType is required
      DateType: 0,
      FromDate: new Date().toISOString().split('T')[0],
      ToDate: null,
      SentTo: null,
      Comment: null,
      EnteredBy: null,
      EnteredDate: null,
      ModifiedBy: null,
      ModifiedDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.DateType = obj.DateType
    this.FromDate = obj.FromDate
    this.ToDate = obj.ToDate
    this.SentTo = obj.SentTo
    this.Comment = obj.Comment
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate
    this.ModifiedBy = obj.ModifiedBy
    this.ModifiedDate = obj.ModifiedDate

    this.DateTypeNavigation = new LuDateType(obj.DateTypeNavigation || {})
    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.ModifiedByNavigation = new User(obj.ModifiedByNavigation || {})
    this.Project = new Project(obj.Project || {})
    this.SentToNavigation = new LuSentTo(obj.SentToNavigation || {})
    this.ProjectFindings = []
    if (obj.ProjectFindings) {
      obj.ProjectFindings.forEach((item) => {
        this.ProjectFindings.push(new ProjectFindings(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.DateTypeNavigation = null
    obj.EnteredByNavigation = null
    obj.ModifiedByNavigation = null
    obj.Project = null
    obj.SentToNavigation = null
    obj.ProjectFindings = []
    if (this.ProjectFindings) {
      this.ProjectFindings.forEach((item) => {
        obj.ProjectFindings.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.DateTypeNavigation = null
    obj.EnteredByNavigation = null
    obj.ModifiedByNavigation = null
    obj.Project = null
    obj.SentToNavigation = null
    obj.ProjectFindings = null
    return obj
  }
}
