import LuPFEntityType from './LuPFEntityType'
export default class LuCOGEntityType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Description: null,
      IsActive: null,
      SortOrder: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder

    this.LuPFEntityType = []
    if (obj.LuPFEntityType) {
      obj.LuPFEntityType.forEach((item) => {
        this.LuPFEntityType.push(new LuPFEntityType(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.LuPFEntityType = []
    if (this.LuPFEntityType) {
      this.LuPFEntityType.forEach((item) => {
        obj.LuPFEntityType.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.LuPFEntityType = null
    return obj
  }
}
