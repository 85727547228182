import User from './User'
import Project from './Project'
export default class ProjectNote {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      //Note is required
      Note: '',
      //EnteredBy is required
      EnteredBy: 0,
      //EnteredDate is required
      EnteredDate: new Date().toISOString().split('T')[0],
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.Note = obj.Note
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate

    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.Project = new Project(obj.Project || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
}
