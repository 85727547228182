import TimeSheetStatus from './TimeSheetStatus'
export default class ApprovalStatus {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description

    this.TimeSheetStatus = []
    if (obj.TimeSheetStatus) {
      obj.TimeSheetStatus.forEach((item) => {
        this.TimeSheetStatus.push(new TimeSheetStatus(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.TimeSheetStatus = []
    if (this.TimeSheetStatus) {
      this.TimeSheetStatus.forEach((item) => {
        obj.TimeSheetStatus.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.TimeSheetStatus = null
    return obj
  }
}
