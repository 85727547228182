import UserProject from './UserProject'
export default class LuProjectRole {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      IsActive: null,
      SortOrder: null,
      DivisionID: null,
      Prefix: null,
      IsPrimary: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder
    this.DivisionID = obj.DivisionID
    this.Prefix = obj.Prefix
    this.IsPrimary = obj.IsPrimary

    this.UserProject = []
    if (obj.UserProject) {
      obj.UserProject.forEach((item) => {
        this.UserProject.push(new UserProject(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.UserProject = []
    if (this.UserProject) {
      this.UserProject.forEach((item) => {
        obj.UserProject.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.UserProject = null
    return obj
  }
}
