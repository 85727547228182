import ActivityCodes from './ActivityCodes'
import ProjectDetail from './ProjectDetail'
export default class LuStandardBudgetHours {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectDetailID is required
      ProjectDetailID: 0,
      //ActivityID is required
      ActivityID: 0,
      //StandardHours is required
      StandardHours: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectDetailID = obj.ProjectDetailID
    this.ActivityID = obj.ActivityID
    this.StandardHours = obj.StandardHours

    this.Activity = new ActivityCodes(obj.Activity || {})
    this.ProjectDetail = new ProjectDetail(obj.ProjectDetail || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Activity = null
    obj.ProjectDetail = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Activity = null
    obj.ProjectDetail = null
    return obj
  }
}
