import ActivityCodes from './ActivityCodes'
import TimeSheetEntry from './TimeSheetEntry'
export default class LuBillableCategory {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Description: null,
      DivisionID: null,
      SortOrder: null,
      IsActive: null,
      Prefix: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.DivisionID = obj.DivisionID
    this.SortOrder = obj.SortOrder
    this.IsActive = obj.IsActive
    this.Prefix = obj.Prefix

    this.ActivityCodes = []
    if (obj.ActivityCodes) {
      obj.ActivityCodes.forEach((item) => {
        this.ActivityCodes.push(new ActivityCodes(item))
      })
    }
    this.TimeSheetEntry = []
    if (obj.TimeSheetEntry) {
      obj.TimeSheetEntry.forEach((item) => {
        this.TimeSheetEntry.push(new TimeSheetEntry(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ActivityCodes = []
    if (this.ActivityCodes) {
      this.ActivityCodes.forEach((item) => {
        obj.ActivityCodes.push(item.removeRelated())
      })
    }
    obj.TimeSheetEntry = []
    if (this.TimeSheetEntry) {
      this.TimeSheetEntry.forEach((item) => {
        obj.TimeSheetEntry.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ActivityCodes = null
    obj.TimeSheetEntry = null
    return obj
  }
}
