import ProjectBanking from './ProjectBanking'
export default class LuExamType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      IsActive: null,
      SortOrder: null,
      Prefix: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description =  obj.Description
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder
    this.Prefix = obj.Prefix

    this.ProjectBanking = []
    if (obj.ProjectBanking) {
      obj.ProjectBanking.forEach((item) => {
        this.ProjectBanking.push(new ProjectBanking(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ProjectBanking = []
    if (this.ProjectBanking) {
      this.ProjectBanking.forEach((item) => {
        obj.ProjectBanking.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ProjectBanking = null
    return obj
  }
}
