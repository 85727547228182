import ExciseExceptions from './ExciseExceptions'
import ExciseTaxRateToCity from './ExciseTaxRateToCity'
import ExciseTaxRateToCounty from './ExciseTaxRateToCounty'
import ExciseTaxRateToCountyStatewide from './ExciseTaxRateToCountyStatewide'
import ExciseTaxRateToSpecialDistrict from './ExciseTaxRateToSpecialDistrict'
export default class LuExciseTaxTypes {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //TaxType is required
      TaxType: '',
      //ProjectDetailID is required
      ProjectDetailID: 0,
      //IsActive is required
      IsActive: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.TaxType = obj.TaxType
    this.ProjectDetailID = obj.ProjectDetailID
    this.IsActive = obj.IsActive
    this.ExciseExceptions = []
    if (obj.ExciseExceptions) {
      obj.ExciseExceptions.forEach((item) => {
        this.ExciseExceptions.push(new ExciseExceptions(item))
      })
    }
    this.ExciseTaxRateToCity = []
    if (obj.ExciseTaxRateToCity) {
      obj.ExciseTaxRateToCity.forEach((item) => {
        this.ExciseTaxRateToCity.push(new ExciseTaxRateToCity(item))
      })
    }
    this.ExciseTaxRateToCounty = []
    if (obj.ExciseTaxRateToCounty) {
      obj.ExciseTaxRateToCounty.forEach((item) => {
        this.ExciseTaxRateToCounty.push(new ExciseTaxRateToCounty(item))
      })
    }
    this.ExciseTaxRateToCountyStatewide = []
    if (obj.ExciseTaxRateToCountyStatewide) {
      obj.ExciseTaxRateToCountyStatewide.forEach((item) => {
        this.ExciseTaxRateToCountyStatewide.push(new ExciseTaxRateToCountyStatewide(item))
      })
    }
    this.ExciseTaxRateToSpecialDistrict = []
    if (obj.ExciseTaxRateToSpecialDistrict) {
      obj.ExciseTaxRateToSpecialDistrict.forEach((item) => {
        this.ExciseTaxRateToSpecialDistrict.push(new ExciseTaxRateToSpecialDistrict(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ExciseExceptions = []
    if (this.ExciseExceptions) {
      this.ExciseExceptions.forEach((item) => {
        obj.ExciseExceptions.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToCity = []
    if (this.ExciseTaxRateToCity) {
      this.ExciseTaxRateToCity.forEach((item) => {
        obj.ExciseTaxRateToCity.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToCounty = []
    if (this.ExciseTaxRateToCounty) {
      this.ExciseTaxRateToCounty.forEach((item) => {
        obj.ExciseTaxRateToCounty.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToCountyStatewide = []
    if (this.ExciseTaxRateToCountyStatewide) {
      this.ExciseTaxRateToCountyStatewide.forEach((item) => {
        obj.ExciseTaxRateToCountyStatewide.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToSpecialDistrict = []
    if (this.ExciseTaxRateToSpecialDistrict) {
      this.ExciseTaxRateToSpecialDistrict.forEach((item) => {
        obj.ExciseTaxRateToSpecialDistrict.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ExciseExceptions = null
    obj.ExciseTaxRateToCity = null
    obj.ExciseTaxRateToCounty = null
    obj.ExciseTaxRateToCountyStatewide = null
    obj.ExciseTaxRateToSpecialDistrict = null
    return obj
  }
}
