import LuSpecialDistrict from './LuSpecialDistrict'
import LuExciseTaxRates from './LuExciseTaxRates'
import LuExciseTaxTypes from './LuExciseTaxTypes'
export default class ExciseTaxRateToSpecialDistrict {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //TaxRateID is required
      TaxRateID: 0,
      //SpecialDistrictID is required
      SpecialDistrictID: 0,
      //TaxTypeID is required
      TaxTypeID: 0,
      //EffectiveDate is required
      EffectiveDate: new Date().toISOString().split('T')[0],
      //IsActive is required
      IsActive: true,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.TaxRateID = obj.TaxRateID
    this.SpecialDistrictID = obj.SpecialDistrictID
    this.TaxTypeID = obj.TaxTypeID
    this.EffectiveDate = obj.EffectiveDate
    this.IsActive = obj.IsActive
    this.SpecialDistrict = new LuSpecialDistrict(obj.SpecialDistrict || {})
    this.TaxRate = new LuExciseTaxRates(obj.TaxRate || {})
    this.TaxType = new LuExciseTaxTypes(obj.TaxType || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.SpecialDistrict = null
    obj.TaxRate = null
    obj.TaxType = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.SpecialDistrict = null
    obj.TaxRate = null
    obj.TaxType = null
    return obj
  }
}
