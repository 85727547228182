import LuCOGEntityType from './LuCOGEntityType'
import AuditEntityPublicFunds from './AuditEntityPublicFunds'
export default class LuPFEntityType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      //COGEntityTypeID is required
      COGEntityTypeID: 0,
      IsActive: null,
      SortOrder: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description =  obj.Description
    this.COGEntityTypeID = obj.COGEntityTypeID
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder

    this.COGEntityType = new LuCOGEntityType(obj.COGEntityType || {})
    this.AuditEntityPublicFunds = []
    if (obj.AuditEntityPublicFunds) {
      obj.AuditEntityPublicFunds.forEach((item) => {
        this.AuditEntityPublicFunds.push(new AuditEntityPublicFunds(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.COGEntityType = null
    obj.AuditEntityPublicFunds = []
    if (this.AuditEntityPublicFunds) {
      this.AuditEntityPublicFunds.forEach((item) => {
        obj.AuditEntityPublicFunds.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.COGEntityType = null
    obj.AuditEntityPublicFunds = null
    return obj
  }
}
