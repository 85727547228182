import Division from './Division'
import LuStandardBudgetHours from './LuStandardBudgetHours'
import Project from './Project'
export default class ProjectDetail {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Description: null,
      Prefix: null,
      DivisionID: null,
      IsActive: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.Prefix = obj.Prefix
    this.DivisionID = obj.DivisionID
    this.IsActive = obj.IsActive

    this.Division = new Division(obj.Division || {})
    this.LuStandardBudgetHours = []
    if (obj.LuStandardBudgetHours) {
      obj.LuStandardBudgetHours.forEach((item) => {
        this.LuStandardBudgetHours.push(new LuStandardBudgetHours(item))
      })
    }
    this.Project = []
    if (obj.Project) {
      obj.Project.forEach((item) => {
        this.Project.push(new Project(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.LuStandardBudgetHours = []
    if (this.LuStandardBudgetHours) {
      this.LuStandardBudgetHours.forEach((item) => {
        obj.LuStandardBudgetHours.push(item.removeRelated())
      })
    }
    obj.Project = []
    if (this.Project) {
      this.Project.forEach((item) => {
        obj.Project.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.LuStandardBudgetHours = null
    obj.Project = null
    return obj
  }
}
