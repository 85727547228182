import TimeSheetEntry from './TimeSheetEntry'
export default class LeaveSlip {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      IsApproved: false,
      ApprovedBy: null,
      Reason: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.IsApproved = obj.IsApproved
    this.ApprovedBy = obj.ApprovedBy
    this.Reason = obj.Reason

    this.TimeSheetEntry = []
    if (obj.TimeSheetEntry) {
      obj.TimeSheetEntry.forEach((item) => {
        this.TimeSheetEntry.push(new TimeSheetEntry(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.TimeSheetEntry = []
    if (this.TimeSheetEntry) {
      this.TimeSheetEntry.forEach((item) => {
        obj.TimeSheetEntry.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.TimeSheetEntry = null
    return obj
  }
}
