import Division from './Division'
import ProjectDate from './ProjectDate'
export default class LuSentTo {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      SentTo: null,
      DivisionID: null,
      IsActive: null,
      SortOrder: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.SentTo = obj.SentTo
    this.DivisionID = obj.DivisionID
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder

    this.Division = new Division(obj.Division || {})
    this.ProjectDate = []
    if (obj.ProjectDate) {
      obj.ProjectDate.forEach((item) => {
        this.ProjectDate.push(new ProjectDate(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.ProjectDate = []
    if (this.ProjectDate) {
      this.ProjectDate.forEach((item) => {
        obj.ProjectDate.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.ProjectDate = null
    return obj
  }
}
