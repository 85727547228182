import ProjectDetail from './ProjectDetail'
import AuditSubEntity from './AuditSubEntity'
import ProjectBanking from './ProjectBanking'
import ProjectLicense from './ProjectLicense'
import ExciseStratum from './ExciseStratum'
import ProjectBudgetedHours from './ProjectBudgetedHours'
import ProjectCollections from './ProjectCollections'
import ProjectDate from './ProjectDate'
import ProjectExpenses from './ProjectExpenses'
import ProjectNote from './ProjectNote'
import ProjectRefunds from './ProjectRefunds'
import ProjectReportedValues from './ProjectReportedValues'
import ProjectReviews from './ProjectReviews'
import TimeSheetEntry from './TimeSheetEntry'
import UserProject from './UserProject'
export default class Project {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      SubEntityID: 0,
      ProjectNumber: '',
      Description: '',
      AuditStart: null,
      AuditEnd: null,
      DepartmentStrategy: null,
      DivisionID: 0,
      ProjectDetailsID: null,
      AddToWolfs: true,
      IsActive: true,
      IsApproved: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.SubEntityID = obj.SubEntityID
    this.ProjectNumber = obj.ProjectNumber
    this.Description = obj.Description
    this.AuditStart = obj.AuditStart
    this.AuditEnd = obj.AuditEnd
    this.DepartmentStrategy = obj.DepartmentStrategy
    this.DivisionID = obj.DivisionID
    this.ProjectDetailsID = obj.ProjectDetailsID
    this.AddToWolfs = obj.AddToWolfs
    this.IsActive = obj.IsActive
    this.IsApproved = obj.IsApproved

    this.ProjectDetails = new ProjectDetail(obj.ProjectDetails || {})
    this.SubEntity = new AuditSubEntity(obj.SubEntity || {})
    this.ProjectBanking = new ProjectBanking(obj.ProjectBanking || {})
    this.ProjectLicense = new ProjectLicense(obj.ProjectLicense || {})
    this.ExciseStratum = []
    if (obj.ExciseStratum) {
      obj.ExciseStratum.forEach((item) => {
        this.ExciseStratum.push(new ExciseStratum(item))
      })
    }
    this.ProjectBudgetedHours = []
    if (obj.ProjectBudgetedHours) {
      obj.ProjectBudgetedHours.forEach((item) => {
        this.ProjectBudgetedHours.push(new ProjectBudgetedHours(item))
      })
    }
    this.ProjectCollections = []
    if (obj.ProjectCollections) {
      obj.ProjectCollections.forEach((item) => {
        this.ProjectCollections.push(new ProjectCollections(item))
      })
    }
    this.ProjectDate = []
    if (obj.ProjectDate) {
      obj.ProjectDate.forEach((item) => {
        this.ProjectDate.push(new ProjectDate(item))
      })
    }
    this.ProjectExpenses = []
    if (obj.ProjectExpenses) {
      obj.ProjectExpenses.forEach((item) => {
        this.ProjectExpenses.push(new ProjectExpenses(item))
      })
    }
    this.ProjectNote = []
    if (obj.ProjectNote) {
      obj.ProjectNote.forEach((item) => {
        this.ProjectNote.push(new ProjectNote(item))
      })
    }
    this.ProjectRefunds = []
    if (obj.ProjectRefunds) {
      obj.ProjectRefunds.forEach((item) => {
        this.ProjectRefunds.push(new ProjectRefunds(item))
      })
    }
    this.ProjectReportedValues = []
    if (obj.ProjectReportedValues) {
      obj.ProjectReportedValues.forEach((item) => {
        this.ProjectReportedValues.push(new ProjectReportedValues(item))
      })
    }
    this.ProjectReviews = []
    if (obj.ProjectReviews) {
      obj.ProjectReviews.forEach((item) => {
        this.ProjectReviews.push(new ProjectReviews(item))
      })
    }
    this.TimeSheetEntry = []
    if (obj.TimeSheetEntry) {
      obj.TimeSheetEntry.forEach((item) => {
        this.TimeSheetEntry.push(new TimeSheetEntry(item))
      })
    }
    this.UserProject = []
    if (obj.UserProject) {
      obj.UserProject.forEach((item) => {
        this.UserProject.push(new UserProject(item))
      })
    }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    if (
      JSON.stringify(this.ProjectLicense.root()) ==
      JSON.stringify(new ProjectLicense().root())
    ) {
      obj.ProjectLicense = null
    } else {
      obj.ProjectLicense = this.ProjectLicense.root()
    }
    if (
      JSON.stringify(this.ProjectBanking.root()) ==
      JSON.stringify(new ProjectBanking().root())
    ) {
      obj.ProjectBanking = null
    } else {
      obj.ProjectBanking = this.ProjectBanking.root()
    }
    obj.ProjectDetails = null
    obj.SubEntity = null
    obj.ExciseStratum = null
    obj.ProjectBudgetedHours = null
    obj.ProjectCollections = null
    obj.ProjectDate = null
    obj.ProjectNote = null
    obj.ProjectRefunds = null
    obj.ProjectReportedValues = null
    obj.ProjectReviews = null
    obj.TimeSheetEntry = null
    obj.UserProject = null
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.SubEntity = null
    obj.ProjectLicense = null
    this.ExciseStratum = []
    if (obj.ExciseStratum) {
      obj.ExciseStratum.forEach((item) => {
        this.ExciseStratum.push(new ExciseStratum(item))
      })
    }
    obj.ProjectBudgetedHours = []
    if (this.ProjectBudgetedHours) {
      this.ProjectBudgetedHours.forEach((item) => {
        obj.ProjectBudgetedHours.push(item.removeRelated())
      })
    }
    obj.ProjectCollections = []
    if (this.ProjectCollections) {
      this.ProjectCollections.forEach((item) => {
        obj.ProjectCollections.push(item.removeRelated())
      })
    }
    obj.ProjectDate = []
    if (this.ProjectDate) {
      this.ProjectDate.forEach((item) => {
        obj.ProjectDate.push(item.removeRelated())
      })
    }
    obj.ProjectExpenses = []
    if (this.ProjectExpenses) {
      this.ProjectExpenses.forEach((item) => {
        obj.ProjectExpenses.push(item.removeRelated())
      })
    }
    obj.ProjectNote = []
    if (this.ProjectNote) {
      this.ProjectNote.forEach((item) => {
        obj.ProjectNote.push(item.removeRelated())
      })
    }
    obj.ProjectRefunds = []
    if (this.ProjectRefunds) {
      this.ProjectRefunds.forEach((item) => {
        obj.ProjectRefunds.push(item.removeRelated())
      })
    }
    obj.ProjectReportedValues = []
    if (this.ProjectReportedValues) {
      this.ProjectReportedValues.forEach((item) => {
        obj.ProjectReportedValues.push(item.removeRelated())
      })
    }
    obj.TimeSheetEntry = []
    if (this.TimeSheetEntry) {
      this.TimeSheetEntry.forEach((item) => {
        obj.TimeSheetEntry.push(item.removeRelated())
      })
    }
    obj.UserProject = []
    if (this.UserProject) {
      this.UserProject.forEach((item) => {
        obj.UserProject.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ProjectDetails = null
    obj.SubEntity = null
    obj.ProjectBanking = null
    obj.ProjectLicense = null
    obj.ProjectBudgetedHours = null
    obj.ProjectCollections = null
    obj.ProjectDate = null
    obj.ProjectExpenses = null
    obj.ProjectNote = null
    obj.ProjectRefunds = null
    obj.ProjectReportedValues = null
    obj.ProjectReviews = null
    obj.TimeSheetEntry = null
    obj.UserProject = null
    return obj
  }
}
