import User from './User'
import Project from './Project'
import LuReportedType from './LuReportedType'
export default class ProjectReportedValues {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      //ReportedValue is required
      ReportedValue: null,
      //ReportedValueTypeID is required
      ReportedValueTypeID: 0,
      EnteredBy: null,
      EnteredDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.ReportedValue = obj.ReportedValue
    this.ReportedValueTypeID = obj.ReportedValueTypeID
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate

    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.Project = new Project(obj.Project || {})
    this.ReportedValueType = new LuReportedType(obj.ReportedValueType || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    obj.Project = null
    obj.ReportedValueType = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    obj.Project = null
    obj.ReportedValueType = null
    return obj
  }
}
