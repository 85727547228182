import TimeSheet from './TimeSheet'
import User from './User'
export default class WorkWeekType {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
      //Active is required
      Active: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description
    this.Active = obj.Active

    this.TimeSheet = []
    if (obj.TimeSheet) {
      obj.TimeSheet.forEach((item) => {
        this.TimeSheet.push(new TimeSheet(item))
      })
    }
    this.User = []
    obj.TimeSheet = []
    if (this.TimeSheet) {
      this.TimeSheet.forEach((item) => {
        obj.TimeSheet.push(item.removeRelated())
      })
    }
    if (obj.User) {
      obj.User.forEach((item) => {
        this.User.push(new User(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.User = []
    if (this.User) {
      this.User.forEach((item) => {
        obj.User.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.TimeSheet = null
    obj.User = null
    return obj
  }
}
