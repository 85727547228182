import LuCity from './LuCity'
import LuCounty from './LuCounty'
export default class CityCounty {
  constructor(objIn = {}) {
    const defaults = {
      //CityID is required
      CityID: 0,
      //CountyID is required
      CountyID: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.CityID = obj.CityID
    this.CountyID = obj.CountyID
    this.City = new LuCity(obj.City || {})
    this.County = new LuCounty(obj.County || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.City = null
    obj.County = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.City = null
    obj.County = null
    return obj
  }
}
