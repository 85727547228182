import ActivityCodes from './ActivityCodes'
import AuditEntityDivision from './AuditEntityDivision'
import LuDateType from './LuDateType'
import LuFindingType from './LuFindingType'
import LuReportedType from './LuReportedType'
import LuSentTo from './LuSentTo'
import ProjectDetail from './ProjectDetail'
import SubDivision from './SubDivision'
import User from './User'
export default class Division {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //DivisionName is required
      DivisionName: '',
      //DivisionNumber is required
      DivisionNumber: '',
      //DivisionStatus is required
      DivisionStatus: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.DivisionName = obj.DivisionName
    this.DivisionNumber = obj.DivisionNumber
    this.DivisionStatus = obj.DivisionStatus

    this.ActivityCodes = []
    if (obj.ActivityCodes) {
      obj.ActivityCodes.forEach((item) => {
        this.ActivityCodes.push(new ActivityCodes(item))
      })
    }
    this.AuditEntityDivision = []
    if (obj.AuditEntityDivision) {
      obj.AuditEntityDivision.forEach((item) => {
        this.AuditEntityDivision.push(new AuditEntityDivision(item))
      })
    }
    this.LuDateType = []
    if (obj.LuDateType) {
      obj.LuDateType.forEach((item) => {
        this.LuDateType.push(new LuDateType(item))
      })
    }
    this.LuFindingType = []
    if (obj.LuFindingType) {
      obj.LuFindingType.forEach((item) => {
        this.LuFindingType.push(new LuFindingType(item))
      })
    }
    this.LuReportedType = []
    if (obj.LuReportedType) {
      obj.LuReportedType.forEach((item) => {
        this.LuReportedType.push(new LuReportedType(item))
      })
    }
    this.LuSentTo = []
    if (obj.LuSentTo) {
      obj.LuSentTo.forEach((item) => {
        this.LuSentTo.push(new LuSentTo(item))
      })
    }
    this.ProjectDetail = []
    if (obj.ProjectDetail) {
      obj.ProjectDetail.forEach((item) => {
        this.ProjectDetail.push(new ProjectDetail(item))
      })
    }
    this.SubDivision = []
    if (obj.SubDivision) {
      obj.SubDivision.forEach((item) => {
        this.SubDivision.push(new SubDivision(item))
      })
    }
    this.User = []
    if (obj.User) {
      obj.User.forEach((item) => {
        this.User.push(new User(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.ActivityCodes = []
    if (this.ActivityCodes) {
      this.ActivityCodes.forEach((item) => {
        obj.ActivityCodes.push(item.removeRelated())
      })
    }
    obj.AuditEntityDivision = []
    if (this.AuditEntityDivision) {
      this.AuditEntityDivision.forEach((item) => {
        obj.AuditEntityDivision.push(item.removeRelated())
      })
    }
    obj.LuDateType = []
    if (this.LuDateType) {
      this.LuDateType.forEach((item) => {
        obj.LuDateType.push(item.removeRelated())
      })
    }
    obj.LuFindingType = []
    if (this.LuFindingType) {
      this.LuFindingType.forEach((item) => {
        obj.LuFindingType.push(item.removeRelated())
      })
    }
    obj.LuReportedType = []
    if (this.LuReportedType) {
      this.LuReportedType.forEach((item) => {
        obj.LuReportedType.push(item.removeRelated())
      })
    }
    obj.LuSentTo = []
    if (this.LuSentTo) {
      this.LuSentTo.forEach((item) => {
        obj.LuSentTo.push(item.removeRelated())
      })
    }
    obj.ProjectDetail = []
    if (this.ProjectDetail) {
      this.ProjectDetail.forEach((item) => {
        obj.ProjectDetail.push(item.removeRelated())
      })
    }
    obj.SubDivision = []
    if (this.SubDivision) {
      this.SubDivision.forEach((item) => {
        obj.SubDivision.push(item.removeRelated())
      })
    }
    obj.User = []
    if (this.User) {
      this.User.forEach((item) => {
        obj.User.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.ActivityCodes = null
    obj.AuditEntityDivision = null
    obj.LuDateType = null
    obj.LuFindingType = null
    obj.LuReportedType = null
    obj.LuSentTo = null
    obj.ProjectDetail = null
    obj.SubDivision = null
    obj.User = null
    return obj
  }
}
