import Division from './Division'
import EmployeePayType from './EmployeePayType'
import EmployeeStatus from './EmployeeStatus'
import EmployeeType from './EmployeeType'
import WorkWeekType from './WorkWeekType'
import ProjectBudgetedHours from './ProjectBudgetedHours'
import ProjectCollections from './ProjectCollections'
import ProjectDate from './ProjectDate'
import ProjectExpenses from './ProjectExpenses'
import ProjectFindings from './ProjectFindings'
import ProjectNote from './ProjectNote'
import ProjectRefunds from './ProjectRefunds'
import ProjectReportedValues from './ProjectReportedValues'
import ProjectReviews from './ProjectReviews'
import TimeSheet from './TimeSheet'
import TimeSheetStatus from './TimeSheetStatus'
import UserProject from './UserProject'
import UserRole from './UserRole'
export default class User {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //FirstName is required
      FirstName: '',
      //LastName is required
      LastName: '',
      //eMail is required
      eMail: '',
      //EmployeeStatusID is required
      EmployeeStatusID: 0,
      EmployeeTypeID: 0,
      WorkWeekTypeID: 0,
      DivisionID: 0,
      SubdivisionID: 0,
      SupervisorID: 0,
      TimesheetVerifierID: 0,
      AdministratorID: 0,
      DirectorID: 0,
      EmployeeID: '',
      IsDeleted: false,
      EmployeePayTypeID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.FirstName = obj.FirstName
    this.LastName = obj.LastName
    this.eMail = obj.eMail
    this.EmployeeStatusID = obj.EmployeeStatusID
    this.EmployeeTypeID = obj.EmployeeTypeID
    this.WorkWeekTypeID = obj.WorkWeekTypeID
    this.DivisionID = obj.DivisionID
    this.SubdivisionID = obj.SubdivisionID
    this.SupervisorID = obj.SupervisorID
    this.TimesheetVerifierID = obj.TimesheetVerifierID
    this.AdministratorID = obj.AdministratorID
    this.DirectorID = obj.DirectorID
    this.EmployeeID = obj.EmployeeID
    this.IsDeleted = obj.IsDeleted
    this.EmployeePayTypeID = obj.EmployeePayTypeID

    this.Division = new Division(obj.Division || {})
    this.EmployeePayType = new EmployeePayType(obj.EmployeePayType || {})
    this.EmployeeStatus = new EmployeeStatus(obj.EmployeeStatus || {})
    this.EmployeeType = new EmployeeType(obj.EmployeeType || {})
    this.WorkWeekType = new WorkWeekType(obj.WorkWeekType || {})
    this.ProjectBudgetedHours = []
    if (obj.ProjectBudgetedHours) {
      obj.ProjectBudgetedHours.forEach((item) => {
        this.ProjectBudgetedHours.push(new ProjectBudgetedHours(item))
      })
    }
    this.ProjectCollections = []
    if (obj.ProjectCollections) {
      obj.ProjectCollections.forEach((item) => {
        this.ProjectCollections.push(new ProjectCollections(item))
      })
    }
    this.ProjectDate = []
    if (obj.ProjectDate) {
      obj.ProjectDate.forEach((item) => {
        this.ProjectDate.push(new ProjectDate(item))
      })
    }
    this.ProjectExpensesAuditor = []
    if (obj.ProjectExpensesAuditor) {
      obj.ProjectExpensesAuditor.forEach((item) => {
        this.ProjectExpensesAuditor.push(new ProjectExpenses(item))
      })
    }
    this.ProjectExpensesEnteredByNavigation = []
    if (obj.ProjectExpensesEnteredByNavigation) {
      obj.ProjectExpensesEnteredByNavigation.forEach((item) => {
        this.ProjectExpensesEnteredByNavigation.push(new ProjectExpenses(item))
      })
    }
    this.ProjectFindings = []
    if (obj.ProjectFindings) {
      obj.ProjectFindings.forEach((item) => {
        this.ProjectFindings.push(new ProjectFindings(item))
      })
    }
    this.ProjectNote = []
    if (obj.ProjectNote) {
      obj.ProjectNote.forEach((item) => {
        this.ProjectNote.push(new ProjectNote(item))
      })
    }
    this.ProjectRefunds = []
    if (obj.ProjectRefunds) {
      obj.ProjectRefunds.forEach((item) => {
        this.ProjectRefunds.push(new ProjectRefunds(item))
      })
    }
    this.ProjectReportedValues = []
    if (obj.ProjectReportedValues) {
      obj.ProjectReportedValues.forEach((item) => {
        this.ProjectReportedValues.push(new ProjectReportedValues(item))
      })
    }
    this.ProjectReviewsEnteredByNavigation = []
    if (obj.ProjectReviewsEnteredByNavigation) {
      obj.ProjectReviewsEnteredByNavigation.forEach((item) => {
        this.ProjectReviewsEnteredByNavigation.push(new ProjectReviews(item))
      })
    }
    this.ProjectReviewsReviewer = []
    if (obj.ProjectReviewsReviewer) {
      obj.ProjectReviewsReviewer.forEach((item) => {
        this.ProjectReviewsReviewer.push(new ProjectReviews(item))
      })
    }
    this.TimeSheet = []
    if (obj.TimeSheet) {
      obj.TimeSheet.forEach((item) => {
        this.TimeSheet.push(new TimeSheet(item))
      })
    }
    this.TimeSheetStatus = []
    if (obj.TimeSheetStatus) {
      obj.TimeSheetStatus.forEach((item) => {
        this.TimeSheetStatus.push(new TimeSheetStatus(item))
      })
    }
    this.UserProject = []
    if (obj.UserProject) {
      obj.UserProject.forEach((item) => {
        this.UserProject.push(new UserProject(item))
      })
    }
    this.UserRole = []
    if (obj.UserRole) {
      obj.UserRole.forEach((item) => {
        this.UserRole.push(new UserRole(item))
      })
    }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.EmployeePayType = null
    obj.EmployeeStatus = null
    obj.EmployeeType = null
    obj.WorkWeekType = null
    obj.ProjectBudgetedHours = null
    obj.ProjectCollections = null
    obj.ProjectDate = null
    obj.ProjectExpensesAuditor = null
    obj.ProjectExpensesEnteredByNavigation = null
    obj.ProjectFindings = null
    obj.ProjectNote = null
    bj.ProjectNote = null
    obj.ProjectRefunds = null
    obj.ProjectReportedValues = null
    obj.ProjectReviewsEnteredByNavigation = null
    obj.ProjectReviewsReviewer = null
    obj.TimeSheet = null
    obj.TimeSheetStatus = null
    obj.UserProject = null
    obj.UserRole = []
    if (this.UserRole) {
      this.UserRole.forEach((item) => {
        obj.UserRole.push(item.root())
      })
    }
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.EmployeePayType = null
    obj.EmployeeStatus = null
    obj.EmployeeType = null
    obj.WorkWeekType = null
    obj.ProjectBudgetedHours = []
    if (this.ProjectBudgetedHours) {
      this.ProjectBudgetedHours.forEach((item) => {
        obj.ProjectBudgetedHours.push(item.removeRelated())
      })
    }
    obj.ProjectCollections = []
    if (this.ProjectCollections) {
      this.ProjectCollections.forEach((item) => {
        obj.ProjectCollections.push(item.removeRelated())
      })
    }
    obj.ProjectDate = []
    if (this.ProjectDate) {
      this.ProjectDate.forEach((item) => {
        obj.ProjectDate.push(item.removeRelated())
      })
    }
    this.ProjectExpensesAuditor = []
    if (obj.ProjectExpensesAuditor) {
      obj.ProjectExpensesAuditor.forEach((item) => {
        this.ProjectExpensesAuditor.push(new ProjectExpenses(item))
      })
    }
    this.ProjectExpensesEnteredByNavigation = []
    if (obj.ProjectExpensesEnteredByNavigation) {
      obj.ProjectExpensesEnteredByNavigation.forEach((item) => {
        this.ProjectExpensesEnteredByNavigation.push(new ProjectExpenses(item))
      })
    }
    obj.ProjectFindings = []
    if (this.ProjectFindings) {
      this.ProjectFindings.forEach((item) => {
        obj.ProjectFindings.push(item.removeRelated())
      })
    }
    obj.ProjectNote = []
    if (this.ProjectNote) {
      this.ProjectNote.forEach((item) => {
        obj.ProjectNote.push(item.removeRelated())
      })
    }
    this.ProjectRefunds = []
    if (obj.ProjectRefunds) {
      obj.ProjectRefunds.forEach((item) => {
        this.ProjectRefunds.push(new ProjectRefunds(item))
      })
    }
    obj.ProjectReportedValues = []
    if (this.ProjectReportedValues) {
      this.ProjectReportedValues.forEach((item) => {
        obj.ProjectReportedValues.push(item.removeRelated())
      })
    }
    this.ProjectReviewsEnteredByNavigation = []
    if (obj.ProjectReviewsEnteredByNavigation) {
      obj.ProjectReviewsEnteredByNavigation.forEach((item) => {
        this.ProjectReviewsEnteredByNavigation.push(new ProjectReviews(item))
      })
    }
    this.ProjectReviewsReviewer = []
    if (obj.ProjectReviewsReviewer) {
      obj.ProjectReviewsReviewer.forEach((item) => {
        this.ProjectReviewsReviewer.push(new ProjectReviews(item))
      })
    }
    obj.TimeSheet = []
    if (this.TimeSheet) {
      this.TimeSheet.forEach((item) => {
        obj.TimeSheet.push(item.removeRelated())
      })
    }
    obj.TimeSheetStatus = []
    if (this.TimeSheetStatus) {
      this.TimeSheetStatus.forEach((item) => {
        obj.TimeSheetStatus.push(item.removeRelated())
      })
    }
    obj.UserProject = []
    if (this.UserProject) {
      this.UserProject.forEach((item) => {
        obj.UserProject.push(item.removeRelated())
      })
    }
    obj.UserRole = []
    if (this.UserRole) {
      this.UserRole.forEach((item) => {
        obj.UserRole.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.EmployeePayType = null
    obj.EmployeeStatus = null
    obj.EmployeeType = null
    obj.WorkWeekType = null
    obj.ProjectBudgetedHours = null
    obj.ProjectCollections = null
    obj.ProjectDate = null
    obj.ProjectExpensesAuditor = null
    obj.ProjectExpensesEnteredByNavigation = null
    obj.ProjectFindings = null
    obj.ProjectNote = null
    obj.ProjectRefunds = null
    obj.ProjectReportedValues = null
    obj.ProjectReviewsEnteredByNavigation = null
    obj.ProjectReviewsReviewer = null
    obj.TimeSheet = null
    obj.TimeSheetStatus = null
    obj.UserProject = null
    obj.UserRole = null
    return obj
  }
}
