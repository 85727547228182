import User from './User'
import WorkWeekType from './WorkWeekType'
import HolidayEntry from './HolidayEntry'
import TimeSheetEntry from './TimeSheetEntry'
import TimeSheetStatus from './TimeSheetStatus'
export default class TimeSheet {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //UserID is required
      UserID: 0,
      StartDate: null,
      EndDate: null,
      WorkWeekTypeId: null,
      DivisionID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.UserID = obj.UserID
    this.StartDate = obj.StartDate
    this.EndDate = obj.EndDate
    this.WorkWeekTypeId = obj.WorkWeekTypeId
    this.DivisionID = obj.DivisionID

    this.User = new User(obj.User || {})
    this.WorkWeekType = new WorkWeekType(obj.WorkWeekType || {})
    this.HolidayEntry = []
    if (obj.HolidayEntry) {
      obj.HolidayEntry.forEach((item) => {
        this.HolidayEntry.push(new HolidayEntry(item))
      })
    }
    this.TimeSheetEntry = []
    if (obj.TimeSheetEntry) {
      obj.TimeSheetEntry.forEach((item) => {
        this.TimeSheetEntry.push(new TimeSheetEntry(item))
      })
    }
    this.TimeSheetStatus = []
    if (obj.TimeSheetStatus) {
      obj.TimeSheetStatus.forEach((item) => {
        this.TimeSheetStatus.push(new TimeSheetStatus(item))
      })
    }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.User = null
    obj.WorkWeekType = null
    obj.HolidayEntry = []
    if (this.HolidayEntry) {
      this.HolidayEntry.forEach((item) => {
        obj.HolidayEntry.push(item.root())
      })
    }
    obj.TimeSheetEntry = []
    if (this.TimeSheetEntry) {
      this.TimeSheetEntry.forEach((item) => {
        obj.TimeSheetEntry.push(item.getSaveData())
      })
    }
    obj.TimeSheetStatus = null
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.User = null
    obj.WorkWeekType = null
    obj.HolidayEntry = []
    if (this.HolidayEntry) {
      this.HolidayEntry.forEach((item) => {
        obj.HolidayEntry.push(item.removeRelated())
      })
    }
    obj.TimeSheetEntry = []
    if (this.TimeSheetEntry) {
      this.TimeSheetEntry.forEach((item) => {
        obj.TimeSheetEntry.push(item.removeRelated())
      })
    }
    obj.TimeSheetStatus = []
    if (this.TimeSheetStatus) {
      this.TimeSheetStatus.forEach((item) => {
        obj.TimeSheetStatus.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.User = null
    obj.WorkWeekType = null
    obj.HolidayEntry = null
    obj.TimeSheetEntry = null
    obj.TimeSheetStatus = null
    return obj
  }
}
