import HolidayEntry from './HolidayEntry'
export default class Holiday {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Date: '',
      //Description is required
      Description: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Date = obj.Date
    this.Description = obj.Description

    this.HolidayEntry = []
    if (obj.HolidayEntry) {
      obj.HolidayEntry.forEach((item) => {
        this.HolidayEntry.push(new HolidayEntry(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.HolidayEntry = []
    if (this.HolidayEntry) {
      this.HolidayEntry.forEach((item) => {
        obj.HolidayEntry.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.HolidayEntry = null
    return obj
  }
}
