import EntityAddress from './EntityAddress'
import AuditEntity from './AuditEntity'
import Project from './Project'
import SubEntityContact from './SubEntityContact'
export default class AuditSubEntity {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      EntityID: 0,
      Description: '',
      AddressID: 0,
      PAddressID: 0,
      IsActive: true,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.EntityID = obj.EntityID
    this.Description = obj.Description
    this.AddressID = obj.AddressID
    this.PAddress = new EntityAddress(obj.PAddress || {})
    this.IsActive = obj.IsActive

    this.Address = new EntityAddress(obj.Address || {})
    this.Entity = new AuditEntity(obj.Entity || {})
    this.Project = []
    if (obj.Project) {
      obj.Project.forEach((item) => {
        this.Project.push(new Project(item))
      })
    }
    this.SubEntityContact = []
    if (obj.SubEntityContact) {
      obj.SubEntityContact.forEach((item) => {
        this.SubEntityContact.push(new SubEntityContact(item))
      })
    }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Address = this.Address.root()
    obj.PAddress = this.PAddress.root()
    obj.Entity = null
    obj.Project = null
    obj.SubEntityContact = null
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Address = null
    obj.PAddress = null
    obj.Entity = null
    obj.Project = []
    if (this.Project) {
      this.Project.forEach((item) => {
        obj.Project.push(item.removeRelated())
      })
    }
    obj.SubEntityContact = []
    if (this.SubEntityContact) {
      this.SubEntityContact.forEach((item) => {
        obj.SubEntityContact.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Address = null
    obj.PAddress = null
    obj.Entity = null
    obj.Project = null
    obj.SubEntityContact = null
    return obj
  }
}
