import UserRole from './UserRole'
export default class Role {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Description is required
      Description: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Description = obj.Description

    this.UserRole = []
    if (obj.UserRole) {
      obj.UserRole.forEach((item) => {
        this.UserRole.push(new UserRole(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.UserRole = []
    if (this.UserRole) {
      this.UserRole.forEach((item) => {
        obj.UserRole.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.UserRole = null
    return obj
  }
}
