import LuLicenseType from './LuLicenseType'
// import Project from './Project'
export default class ProjectLicense {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      LicenseNumber: null,
      LicenseTypeID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.LicenseNumber = obj.LicenseNumber
    this.LicenseTypeID = obj.LicenseTypeID

    this.LicenseType = new LuLicenseType(obj.LicenseType || {})
    //cicular reference.. do not load
    // this.Project = new Project(obj.Project || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.LicenseType = null
    obj.Project = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.LicenseType = null
    obj.Project = null
    return obj
  }
}
