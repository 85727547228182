import LuCounty from './LuCounty'
import User from './User'
import LuFindingType from './LuFindingType'
import ProjectDate from './ProjectDate'
export default class ProjectFindings {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectDateID is required
      ProjectDateID: 0,
      ProjectID: null,
      //SentDate is required
      SentDate: new Date().toISOString().split('T')[0],
      Comments: null,
      //FindingsValue is required
      FindingsValue: null,
      //FindingsTypeID is required
      FindingsTypeID: 0,
      CountyId: null,
      EnteredBy: null,
      EnteredDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectDateID = obj.ProjectDateID
    this.ProjectID = obj.ProjectID
    this.SentDate = obj.SentDate
    this.Comments = obj.Comments
    this.FindingsValue = obj.FindingsValue
    this.FindingsTypeID = obj.FindingsTypeID
    this.CountyId = obj.CountyId
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate

    this.County = new LuCounty(obj.County || {})
    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.FindingsType = new LuFindingType(obj.FindingsType || {})
    this.ProjectDate = new ProjectDate(obj.ProjectDate || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.County = null
    obj.EnteredByNavigation = null
    obj.FindingsType = null
    obj.ProjectDate = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.County = null
    obj.EnteredByNavigation = null
    obj.FindingsType = null
    obj.ProjectDate = null
    return obj
  }
}
