import CityCounty from './CityCounty'
import ExciseExceptions from './ExciseExceptions'
import ExciseTaxRateToCity from './ExciseTaxRateToCity'
export default class LuCity {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //City is required
      City: '',
      //IsActive is required
      IsActive: true,
      Population: null,
      DORJurisdiction: null,
      Incorporated: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.City = obj.City
    this.IsActive = obj.IsActive
    this.Population = obj.Population
    this.DORJurisdiction = obj.DORJurisdiction
    this.Incorporated = obj.Incorporated
    this.CityCounty = []
    if (obj.CityCounty) {
      obj.CityCounty.forEach((item) => {
        this.CityCounty.push(new CityCounty(item))
      })
    }
    this.ExciseExceptions = []
    if (obj.ExciseExceptions) {
      obj.ExciseExceptions.forEach((item) => {
        this.ExciseExceptions.push(new ExciseExceptions(item))
      })
    }
    this.ExciseTaxRateToCity = []
    if (obj.ExciseTaxRateToCity) {
      obj.ExciseTaxRateToCity.forEach((item) => {
        this.ExciseTaxRateToCity.push(new ExciseTaxRateToCity(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CityCounty = []
    if (this.CityCounty) {
      this.CityCounty.forEach((item) => {
        obj.CityCounty.push(item.removeRelated())
      })
    }
    obj.ExciseExceptions = []
    if (this.ExciseExceptions) {
      this.ExciseExceptions.forEach((item) => {
        obj.ExciseExceptions.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToCity = []
    if (this.ExciseTaxRateToCity) {
      this.ExciseTaxRateToCity.forEach((item) => {
        obj.ExciseTaxRateToCity.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CityCounty = null
    obj.ExciseExceptions = null
    obj.ExciseTaxRateToCity = null
    return obj
  }
}
