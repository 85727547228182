import Division from './Division'
export default class SubDivision {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //SubDivsionNumber is required
      SubDivsionNumber: 0,
      //DivisionID is required
      DivisionID: 0,
      //SupervisorID is required
      SupervisorID: 0,
      //Description is required
      Description: '',
      //SubDivsionStatus is required
      SubDivsionStatus: false,
      //ProjectPrefix is required
      ProjectPrefix: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.SubDivsionNumber = obj.SubDivsionNumber
    this.DivisionID = obj.DivisionID
    this.SupervisorID = obj.SupervisorID
    this.Description = obj.Description
    this.SubDivsionStatus = obj.SubDivsionStatus
    this.ProjectPrefix = obj.ProjectPrefix
    this.Division = new Division(obj.Division || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    return obj
  }
}
