import User from './User'
import Project from './Project'
export default class ProjectRefunds {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      //Quantity is required
      Quantity: 0,
      //Amount is required
      Amount: null,
      DateReceived: null,
      DateProcessed: null,
      EnteredBy: null,
      EnteredDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.Quantity = obj.Quantity
    this.Amount = obj.Amount
    this.DateReceived = obj.DateReceived
    this.DateProcessed = obj.DateProcessed
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate

    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.Project = new Project(obj.Project || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
}
