import Holiday from './Holiday'
import TimeSheet from './TimeSheet'
export default class HolidayEntry {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //HolidayID is required
      HolidayID: 0,
      //TimeSheetID is required
      TimeSheetID: 0,
      //Hours is required
      Hours: 0,
      DateTaken: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.HolidayID = obj.HolidayID
    this.TimeSheetID = obj.TimeSheetID
    this.Hours = obj.Hours
    this.DateTaken = obj.DateTaken

    this.Holiday = new Holiday(obj.Holiday || {})
    this.TimeSheet = new TimeSheet(obj.TimeSheet || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Holiday = null
    obj.TimeSheet = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Holiday = null
    obj.TimeSheet = null
    return obj
  }
}
