import EntityAddress from './EntityAddress'
import EntityContact from './EntityContact'
import SubEntityContact from './SubEntityContact'
export default class Contact {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      FirstName: '',
      LastName: '',
      OfficeTitle: '',
      Phone: '',
      Extension: '',
      Fax: '',
      Email: '',
      AddressId: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.FirstName = obj.FirstName
    this.LastName = obj.LastName
    this.OfficeTitle = obj.OfficeTitle
    this.Phone = obj.Phone
    this.Extension = obj.Extension
    this.Fax = obj.Fax
    this.Email = obj.Email
    this.AddressId = obj.AddressId

    this.Address = new EntityAddress(obj.Address || {})
    this.EntityContact = []
    if (obj.EntityContact) {
      obj.EntityContact.forEach((item) => {
        this.EntityContact.push(new EntityContact(item))
      })
    }
    this.SubEntityContact = []
    if (obj.SubEntityContact) {
      obj.SubEntityContact.forEach((item) => {
        this.SubEntityContact.push(new SubEntityContact(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Address = null
    obj.EntityContact = []
    if (this.EntityContact) {
      this.EntityContact.forEach((item) => {
        obj.EntityContact.push(item.removeRelated())
      })
    }
    obj.SubEntityContact = []
    if (this.SubEntityContact) {
      this.SubEntityContact.forEach((item) => {
        obj.SubEntityContact.push(item.removeRelated())
      })
    }
    return obj
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.Address = this.Address.root()
    obj.EntityContact = null
    obj.SubEntityContact = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Address = null
    obj.EntityContact = null
    obj.SubEntityContact = null
    return obj
  }
}
