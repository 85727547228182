import Project from './Project';
import LuReviewType from './LuReviewType';
import User from './User';
export default class ProjectReviews {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      //ReviewTypeID is required
      ReviewTypeID: 0,
      DateSubmitted: new Date().toISOString().split('T')[0],
      ReviewerID: null,
      DateCompleted: null,
      EnteredBy: null,
      EnteredDate: null,
    };
    let obj = {};
    Object.assign(obj, defaults, objIn);
    this.ID = obj.ID;
    this.ProjectID = obj.ProjectID;
    this.ReviewTypeID = obj.ReviewTypeID;
    this.DateSubmitted = obj.DateSubmitted;
    this.ReviewerID = obj.ReviewerID;
    this.DateCompleted = obj.DateCompleted;
    this.EnteredBy = obj.EnteredBy;
    this.EnteredDate = obj.EnteredDate;

    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {});
    this.Project = new Project(obj.Project || {});
    this.ReviewType = new LuReviewType(obj.ReviewType || {});
    this.Reviewer = new User(obj.Reviewer || {});
  }
  removeRelated() {
    let obj = Object.assign({}, this);
    obj.EnteredByNavigation = null;
    obj.Project = null;
    obj.ReviewType = null;
    obj.Reviewer = null;
    return obj;
  }
  root() {
    let obj = Object.assign({}, this);
    obj.EnteredByNavigation = null;
    obj.Project = null;
    obj.ReviewType = null;
    obj.Reviewer = null;
    return obj;
  }
}
