import ExciseExceptions from './ExciseExceptions'
import ExciseTaxRateToSpecialDistrict from './ExciseTaxRateToSpecialDistrict'
import LuCounty from './LuCounty'
export default class LuSpecialDistrict {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //SpecialDistrict is required
      SpecialDistrict: '',
      //IsActive is required
      IsActive: true,
      DORJurisdiction: null,
      CountyID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.SpecialDistrict = obj.SpecialDistrict
    this.IsActive = obj.IsActive
    this.DORJurisdiction = obj.DORJurisdiction
    this.CountyID = obj.CountyID
    this.County = new LuCounty(obj.County || {})
    this.ExciseExceptions = []
    if (obj.ExciseExceptions) {
      obj.ExciseExceptions.forEach((item) => {
        this.ExciseExceptions.push(new ExciseExceptions(item))
      })
    }
    this.ExciseTaxRateToSpecialDistrict = []
    if (obj.ExciseTaxRateToSpecialDistrict) {
      obj.ExciseTaxRateToSpecialDistrict.forEach((item) => {
        this.ExciseTaxRateToSpecialDistrict.push(
          new ExciseTaxRateToSpecialDistrict(item)
        )
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.County = null
    obj.ExciseExceptions = []
    if (this.ExciseExceptions) {
      this.ExciseExceptions.forEach((item) => {
        obj.ExciseExceptions.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToSpecialDistrict = []
    if (this.ExciseTaxRateToSpecialDistrict) {
      this.ExciseTaxRateToSpecialDistrict.forEach((item) => {
        obj.ExciseTaxRateToSpecialDistrict.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.County = null
    obj.ExciseExceptions = null
    obj.ExciseTaxRateToSpecialDistrict = null
    return obj
  }
}
