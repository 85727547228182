import LuFindingType from './LuFindingType'
import LuCounty from './LuCounty'
import User from './User'
import Project from './Project'
export default class ProjectCollections {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //ProjectID is required
      ProjectID: 0,
      //CollectionsFindingTypeID is required
      CollectionsFindingTypeID: 0,
      //ReceivedDate is required
      ReceivedDate: new Date().toISOString().split('T')[0],
      //CollectionsValue is required
      CollectionsValue: null,
      CountyID: null,
      CollectionsYear: null,
      Comments: null,
      NovcNumber: null,
      EnteredBy: null,
      EnteredDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.ProjectID = obj.ProjectID
    this.CollectionsFindingTypeID = obj.CollectionsFindingTypeID
    this.ReceivedDate = obj.ReceivedDate
    this.CollectionsValue = obj.CollectionsValue
    this.CountyID = obj.CountyID
    this.CollectionsYear = obj.CollectionsYear
    this.Comments = obj.Comments
    this.NovcNumber = obj.NovcNumber
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate

    this.CollectionsFindingType = new LuFindingType(obj.CollectionsFindingType || {})
    this.County = new LuCounty(obj.County || {})
    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
    this.Project = new Project(obj.Project || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.CollectionsFindingType = null
    obj.County = null
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.CollectionsFindingType = null
    obj.County = null
    obj.EnteredByNavigation = null
    obj.Project = null
    return obj
  }
}
