import AuditEntityPublicFunds from './AuditEntityPublicFunds'
import CityCounty from './CityCounty'
import ExciseExceptions from './ExciseExceptions'
import ExciseTaxRateToCounty from './ExciseTaxRateToCounty'
import ExciseTaxRateToCountyStatewide from './ExciseTaxRateToCountyStatewide'
import ProjectCollections from './ProjectCollections'
import ProjectFindings from './ProjectFindings'
export default class LuCounty {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Name: null,
      Population: null,
      DORJurisdiction: null,
      IsActive: true,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Name = obj.Name
    this.Population = obj.Population
    this.DORJurisdiction = obj.DORJurisdiction
    this.IsActive = obj.IsActive
    this.AuditEntityPublicFunds = []
    if (obj.AuditEntityPublicFunds) {
      obj.AuditEntityPublicFunds.forEach((item) => {
        this.AuditEntityPublicFunds.push(new AuditEntityPublicFunds(item))
      })
    }
    this.CityCounty = []
    if (obj.CityCounty) {
      obj.CityCounty.forEach((item) => {
        this.CityCounty.push(new CityCounty(item))
      })
    }
    this.ExciseExceptionsDeliveredToCounty = []
    if (obj.ExciseExceptionsDeliveredToCounty) {
      obj.ExciseExceptionsDeliveredToCounty.forEach((item) => {
        this.ExciseExceptionsDeliveredToCounty.push(new ExciseExceptions(item))
      })
    }
    this.ExciseExceptionsSoldFromCounty = []
    if (obj.ExciseExceptionsSoldFromCounty) {
      obj.ExciseExceptionsSoldFromCounty.forEach((item) => {
        this.ExciseExceptionsSoldFromCounty.push(new ExciseExceptions(item))
      })
    }
    this.ExciseTaxRateToCounty = []
    if (obj.ExciseTaxRateToCounty) {
      obj.ExciseTaxRateToCounty.forEach((item) => {
        this.ExciseTaxRateToCounty.push(new ExciseTaxRateToCounty(item))
      })
    }
    this.ExciseTaxRateToCountyStatewide = []
    if (obj.ExciseTaxRateToCountyStatewide) {
      obj.ExciseTaxRateToCountyStatewide.forEach((item) => {
        this.ExciseTaxRateToCountyStatewide.push(new ExciseTaxRateToCountyStatewide(item))
      })
    }
    this.ProjectCollections = []
    if (obj.ProjectCollections) {
      obj.ProjectCollections.forEach((item) => {
        this.ProjectCollections.push(new ProjectCollections(item))
      })
    }
    this.ProjectFindings = []
    if (obj.ProjectFindings) {
      obj.ProjectFindings.forEach((item) => {
        this.ProjectFindings.push(new ProjectFindings(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.AuditEntityPublicFunds = []
    if (this.AuditEntityPublicFunds) {
      this.AuditEntityPublicFunds.forEach((item) => {
        obj.AuditEntityPublicFunds.push(item.removeRelated())
      })
    }
    obj.CityCounty = []
    if (this.CityCounty) {
      this.CityCounty.forEach((item) => {
        obj.CityCounty.push(item.removeRelated())
      })
    }
    obj.ExciseExceptionsDeliveredToCounty = []
    if (this.ExciseExceptionsDeliveredToCounty) {
      this.ExciseExceptionsDeliveredToCounty.forEach((item) => {
        obj.ExciseExceptionsDeliveredToCounty.push(item.removeRelated())
      })
    }
    obj.ExciseExceptionsSoldFromCounty = []
    if (this.ExciseExceptionsSoldFromCounty) {
      this.ExciseExceptionsSoldFromCounty.forEach((item) => {
        obj.ExciseExceptionsSoldFromCounty.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToCounty = []
    if (this.ExciseTaxRateToCounty) {
      this.ExciseTaxRateToCounty.forEach((item) => {
        obj.ExciseTaxRateToCounty.push(item.removeRelated())
      })
    }
    obj.ExciseTaxRateToCountyStatewide = []
    if (this.ExciseTaxRateToCountyStatewide) {
      this.ExciseTaxRateToCountyStatewide.forEach((item) => {
        obj.ExciseTaxRateToCountyStatewide.push(item.removeRelated())
      })
    }
    obj.ProjectCollections = []
    if (this.ProjectCollections) {
      this.ProjectCollections.forEach((item) => {
        obj.ProjectCollections.push(item.removeRelated())
      })
    }
    obj.ProjectFindings = []
    if (this.ProjectFindings) {
      this.ProjectFindings.forEach((item) => {
        obj.ProjectFindings.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.AuditEntityPublicFunds = null
    obj.CityCounty = null
    obj.ExciseExceptionsDeliveredToCounty = null
    obj.ExciseExceptionsSoldFromCounty = null
    obj.ExciseTaxRateToCounty = null
    obj.ExciseTaxRateToCountyStatewide = null
    obj.ProjectCollections = null
    obj.ProjectFindings = null
    return obj
  }
}
