import AuditEntityPublicFunds from './AuditEntityPublicFunds'
export default class LuCensusReport {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Name is required
      Name: '',
      //Description is required
      Description: '',
      //IsActive is required
      IsActive: false,
      SortOrder: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Name =  obj.Name
    this.Description =  obj.Description
    this.IsActive = obj.IsActive
    this.SortOrder = obj.SortOrder

    this.AuditEntityPublicFunds = []
    if (obj.AuditEntityPublicFunds) {
      obj.AuditEntityPublicFunds.forEach((item) => {
        this.AuditEntityPublicFunds.push(new AuditEntityPublicFunds(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.AuditEntityPublicFunds = []
    if (this.AuditEntityPublicFunds) {
      this.AuditEntityPublicFunds.forEach((item) => {
        obj.AuditEntityPublicFunds.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.AuditEntityPublicFunds = null
    return obj
  }
}
