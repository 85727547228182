import EntityAddress from './EntityAddress'
export default class LuState {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Name: null,
      Abbreviation: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Name = obj.Name
    this.Abbreviation = obj.Abbreviation

    this.EntityAddress = []
    if (obj.EntityAddress) {
      obj.EntityAddress.forEach((item) => {
        this.EntityAddress.push(new EntityAddress(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.EntityAddress = []
    if (this.EntityAddress) {
      this.EntityAddress.forEach((item) => {
        obj.EntityAddress.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.EntityAddress = null
    return obj
  }
}
