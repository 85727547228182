import Division from './Division'
import AuditEntity from './AuditEntity'
export default class AuditEntityDivision {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //EntityID is required
      EntityID: 0,
      //DivisionID is required
      DivisionID: 0,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.EntityID = obj.EntityID
    this.DivisionID = obj.DivisionID

    this.Division = new Division(obj.Division || {})
    this.Entity = new AuditEntity(obj.Entity || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.Entity = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Division = null
    obj.Entity = null
    return obj
  }
}
