import ApprovalStatus from './ApprovalStatus'
import TimeSheet from './TimeSheet'
import User from './User'
export default class TimeSheetStatus {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //TimeSheetID is required
      TimeSheetID: 0,
      //StatusID is required
      StatusID: 0,
      //UserID is required
      UserID: 0,
      //StatusDate is required
      StatusDate: new Date().toISOString().split('T')[0],
      RejectReason: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.TimeSheetID = obj.TimeSheetID
    this.StatusID = obj.StatusID
    this.UserID = obj.UserID
    this.StatusDate = obj.StatusDate
    this.RejectReason = obj.RejectReason

    this.Status = new ApprovalStatus(obj.Status || {})
    this.TimeSheet = new TimeSheet(obj.TimeSheet || {})
    this.User = new User(obj.User || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Status = null
    obj.TimeSheet = null
    obj.User = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Status = null
    obj.TimeSheet = null
    obj.User = null
    return obj
  }
}
