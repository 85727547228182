import Project from './Project'
import LuProjectRole from './LuProjectRole'
import User from './User'
export default class UserProject {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //UserID is required
      UserID: 0,
      //ProjectID is required
      ProjectID: 0,
      ProjectRoleID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.UserID = obj.UserID
    this.ProjectID = obj.ProjectID
    this.ProjectRoleID = obj.ProjectRoleID

    this.Project = new Project(obj.Project || {})
    this.ProjectRole = new LuProjectRole(obj.ProjectRole || {})
    this.User = new User(obj.User || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Project = null
    obj.ProjectRole = null
    obj.User = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Project = null
    obj.ProjectRole = null
    obj.User = null
    return obj
  }
}
