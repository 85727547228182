import LuBillableCategory from './LuBillableCategory'
import Division from './Division'
import LuStandardBudgetHours from './LuStandardBudgetHours'
import ProjectBudgetedHours from './ProjectBudgetedHours'
import TimeSheetEntry from './TimeSheetEntry'
export default class ActivityCodes {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      Code: 0,
      Description: '',
      DivisionID: 0,
      IsActive: false,
      BillableCategoryID: null,
      Prefix: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Code = obj.Code
    this.Description = obj.Description
    this.DivisionID = obj.DivisionID
    this.IsActive = obj.IsActive
    this.BillableCategoryID = obj.BillableCategoryID
    this.Prefix = obj.Prefix

    this.BillableCategory = new LuBillableCategory(obj.BillableCategory || {})
    this.Division = new Division(obj.Division || {})
    this.LuStandardBudgetHours = []
    if (obj.LuStandardBudgetHours) {
      obj.LuStandardBudgetHours.forEach((item) => {
        this.LuStandardBudgetHours.push(new LuStandardBudgetHours(item))
      })
    }
    this.ProjectBudgetedHours = []
    if (obj.ProjectBudgetedHours) {
      obj.ProjectBudgetedHours.forEach((item) => {
        this.ProjectBudgetedHours.push(new ProjectBudgetedHours(item))
      })
    }
    this.TimeSheetEntry = []
    if (obj.TimeSheetEntry) {
      obj.TimeSheetEntry.forEach((item) => {
        this.TimeSheetEntry.push(new TimeSheetEntry(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.BillableCategory = null
    obj.Division = null
    obj.LuStandardBudgetHours = []
    if (this.LuStandardBudgetHours) {
      this.LuStandardBudgetHours.forEach((item) => {
        obj.LuStandardBudgetHours.push(item.removeRelated())
      })
    }
    obj.ProjectBudgetedHours = []
    if (this.ProjectBudgetedHours) {
      this.ProjectBudgetedHours.forEach((item) => {
        obj.ProjectBudgetedHours.push(item.removeRelated())
      })
    }
    obj.TimeSheetEntry = []
    if (this.TimeSheetEntry) {
      this.TimeSheetEntry.forEach((item) => {
        obj.TimeSheetEntry.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.BillableCategory = null
    obj.Division = null
    obj.LuStandardBudgetHours = null
    obj.ProjectBudgetedHours = null
    obj.TimeSheetEntry = null
    return obj
  }
}
